export default {
  sizes: {
    '2xs': {
      height: "0.5px"
    },
    xs: {
      height: '1px',
    },
    sm: {
      height: '2px',
    },
    md: {
      height: '4px',
    },
    lg: {
      height: '6px',
    },
    xl: {
      height: '8px'
    }
  },
};

