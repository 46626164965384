export const FormLayout = {
  parts: ["mainContent", "asideContent"],
  variants: {
    default: {
      mainContent: {
        shadow: "0px 4px 14px rgba(0, 0, 0, 0.05)",
        padding: "20px",
        backgroundColor: "white",
        borderRadius: "20px"
      },
      asideContent: {
        borderRadius: "20px"
      }
    },
  },
  defaultProps:{
    variant:"default",
  }
}
