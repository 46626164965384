/**
 * Indique si la valeur est null
 * @author Cavelier Mickael <mickael.cavelier@wyzengroup.com>
 * @param {*} value
 * @return {bool}
 */
 export const isNull = (value) => (value === null);
 /**
  * Indique si la valeur est undefined
  * @author Cavelier Mickael <mickael.cavelier@wyzengroup.com>
  * @param {*} value
  * @return {bool}
  *
  */
 export const isUndefined = (value) => (value === undefined);
 /**
  * Indique si la valeur est une chaîne de caractère
  * @author Cavelier Mickael <mickael.cavelier@wyzengroup.com>
  * @param {*} value
  * @return {bool}
  */
 export const isString = (value) => (typeof (value) === 'string');
 /**
  * Indique si la valeur est une entier
  * @author Cavelier Mickael <mickael.cavelier@wyzengroup.com>
  * @param {*} value
  * @return {bool}
  */
 export const isInt = (value) => (typeof (value) === 'number');
 /**
  * Indique si le param1 === param2
  * @author Cavelier Mickael <mickael.cavelier@wyzengroup.com>
  *
  * @param {*} value
  * @param {*} valueExpected
  * @return {bool}
  */
 export const isEqual = (param1, param2) => ((param1 === param2));
 /**
  * Indique si la valeur est une chaine de caractère vide ou non
  * @author Cavelier Mickael <mickael.cavelier@wyzengroup.com>
  * @param {*} value
  * @return {bool}
  */
 export const isStrEmpty = (value) => (value === '');
 /**
  * Indique si la valeur est nulle ou undefined
  * @author Cavelier Mickael <mickael.cavelier@wyzengroup.com>
  * @param {*} value
  * @return {bool}
  */
 export const isNullOrUndefined = (value) => (!!(value === undefined || value === null));
 /**
  * Indique si un tableau est vide ou non
  * @author Cavelier Mickael <mickael.cavelier@wyzengroup.com>
  * @param {*} value
  * @return {bool}
  */
 export const isEmpty = (array) => {
     if (isUndefined(array)) {
         return true;
     }
     return array.length === 0;
 };
 /**
  * Indique une valeur est true ou false
  * @author Cavelier Mickael <mickael.cavelier@wyzengroup.com>
  * @param {*} value
  * @return {bool}
  */
 export const isTrue = (value) => (value === true);
 /**
  * Indique si un object est vide
  * @author Cavelier Mickael <mickael.cavelier@wyzengroup.com>
  * @param {object} object
  * @return {bool}
  */
 export const isObjectEmpty = (object) => ((Object.entries(object).length === 0) === true);
 /**
  * Indique si la variable est un objet
  * @author Cavelier Mickael <mickael.cavelier@wyzengroup.com>
  * @param {object} object
  * @return {bool}
  */
 export const isObject = (target) => ((typeof (target) === 'object'));
